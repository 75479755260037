body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-nav {
  background-color: lightgray;
  padding: 20px;
}

nav a {
  margin-right: 20px;
  text-decoration: none;
  font-size: 20px;
}

nav a.active {
  font-weight: bold;
  font-size: 23px;
}
